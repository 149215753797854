import { StepperComponent } from "../components/Stepper/Stepper";
import { React, useEffect, useState, useContext } from "react";
import { GlobalContext } from "../context/GlobalContext";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { Center, Loader } from "@mantine/core";
import { Container } from "react-bootstrap";
import { IdTokenData } from "../components/DataDisplay/DataDisplay";
import axios from "axios";
import { CarePlusEndpoints } from "../models/constants/carePlusEndpoints";
import { useNavigate } from "react-router";
import { Paths } from "../models/constants/paths";
import ConfirmAddressForm from "../components/ConfirmAddressForm/ConfirmAddressForm";
import { defaultContext } from "../models/constants/defaultContext";
import { appInsights } from "../components/ApplicationInsights/ApplicationInsights";
import { useTranslation } from "react-i18next";
import { PageTitleMetaTags } from "../components/PageTitleMetaTags/PageTitleMetaTags";
import { AzureBlobFiles } from "../models/constants/azureBlobFiles";
import { jwtDecode } from "jwt-decode";

/***
 * Component to detail ID token claims with a description for each claim. For more details on ID token claims, please check the following links:
 * ID token Claims: https://docs.microsoft.com/en-us/azure/active-directory/develop/id-tokens#claims-in-an-id-token
 * Optional Claims:  https://docs.microsoft.com/en-us/azure/active-directory/develop/active-directory-optional-claims#v10-and-v20-optional-claims-set
 */
export const ConfirmAddress = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const activeAccount = instance.getActiveAccount();
  const [isLoading, setIsLoading] = useState(true);
  const { context, setContext } = useContext(GlobalContext);
  const stripePromise = loadStripe(String(process.env.REACT_APP_STRIPE_API_KEY));
  const { t } = useTranslation();
  const pageTitle = t("browser-tab-titles.confirm-address");
  const pageDescription = t("browser-tab-descriptions.confirm-address");
  const metaTitle = t("browser-meta-titles.confirm-address");
  const metaDescription = t("browser-meta-descriptions.confirm-address");

  useEffect(() => {
    if (sessionStorage.getItem("context")) {
      let contextJson = JSON.parse(sessionStorage.getItem("context"));
      const hash = window.location.hash.substring(1); // Remove the leading #
      const params = new URLSearchParams(hash);
      const idToken = params.get("id_token");
      const decodedToken = jwtDecode(idToken);
      contextJson.user.email = decodedToken.email;
      setContext(contextJson);
      sessionStorage.removeItem("context");
      if (!contextJson.completed_pages.confirm_details) {
        setContext(defaultContext);
        navigate(Paths.Home);
      }
    }
    else if (!context.completed_pages.confirm_details) {
      setContext(defaultContext);
      navigate(Paths.Home);
    }
  }, [])

  useEffect(() => {
    if (context.user.dgb_id) {
      axios.get(process.env.REACT_APP_BACKEND_API_URL + CarePlusEndpoints.DisqualifyingQuestions, {
        params: {
          is_6inches_lift_kit: context.user_answers.lift_kit_over_six_inches,
          is_aftermarket_performance: context.user_answers.aftermarket_enhancements,
        },
        timeout: process.env.REACT_APP_API_TIMEOUT,
      })
      .then((res) => {
        if (res?.data?.ineligibility_reasons.length) {
          navigate(Paths.IneligibleVehicle, { replace: true, state: {reasons: res.data.ineligibility_reasons}});
        }
        setIsLoading(false);
      })
      .catch((error) => {
        appInsights.trackException({ error: new Error(error), severityLevel: 1 });
        navigate(Paths.Error)
      });
    }
  }, [context.user.dgb_id]);
  
  const options = {
    // Fully customizable with appearance API.
    appearance: { variables: { fontFamily: 'initial'}},
  };

  return isLoading ? (
    <div className="full-container">
      <div className="content-container">
        <Center>
          <Loader color="#006B5B" size="xl" />
        </Center>
      </div>
    </div>
  ) : (
    <>
      <PageTitleMetaTags
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        metaImage={AzureBlobFiles.MetaImage}
      />
      <AuthenticatedTemplate>
        {activeAccount ? (
          <Container>
            <IdTokenData idTokenClaims={activeAccount.idTokenClaims} />
            <StepperComponent activeState={1} />
            <Elements stripe={stripePromise} options={options}>
              <ConfirmAddressForm></ConfirmAddressForm>
            </Elements>            
          </Container>
        ) : null}
      </AuthenticatedTemplate>
    </>
  );
};
