import { IconCar, IconTool, IconAlertTriangle } from "@tabler/icons-react";
import { Paper, ThemeIcon, Button, Text } from "@mantine/core";
import { useTranslation, Trans } from "react-i18next";
import { React, useContext } from "react";
import { IneligibilityReasons } from "../../models/constants/ineligibilityReasons";
import "./VehicleDetails.scss";
import { GlobalContext } from "../../context/GlobalContext";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { Paths } from "../../models/constants/paths";
import { boldOrangeText } from "../../utils/textStyling";

export const VehicleDetails = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { context, setContext } = useContext(GlobalContext);
  let continueDisabled = !!props.vehicle?.ineligibility_reason;

  // Initial Quote form removes commas from mileage values,
  // so this adds them back in for proper formatting
  function numberWithCommas(numberString) {
    if (!numberString) {
      return;
    }

    return (
      numberString?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") +
      " " +
      t("mileage-abbreviation")
    );
  }

  function formatDate(dateString) {
    let date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}`;
  }

  function generateIneligibilityText() {
    let text;
    switch (props.vehicle.ineligibility_reason) {
      case IneligibilityReasons.INVALID_DVI:
        text = t("recent-inspection-required");
        return text;
      case IneligibilityReasons.INVALID_YMMM:
      case IneligibilityReasons.INELIGIBLE_MILEAGE:
      case IneligibilityReasons.INELIGIBLE_VEHICLE_AGE:
        text = t("invalid-ymmm");
        return text;
      case IneligibilityReasons.ALREADY_ENROLLED:
        text = t("already-enrolled");
        return text;
      case IneligibilityReasons.INELIGIBLE_NO_MAKE_MATCH:
      case IneligibilityReasons.INELIGIBLE_MAKE_MODEL:
        text = t("ineligible");
        return text;
      case IneligibilityReasons.INVALID_VEHICLE_DATA:
        text = t("invalid-vehicle-data");
        return text;
      case IneligibilityReasons.SHOP_NOT_PARTICIPATING:
        text = t("shop-not-participating");
        return text;
      default:
        break;
    }
  }

  function handleVehicleSelection() {
    setContext({
      ...context,
      selected_vin: props.vehicle.vin,
      vehicle_data: {
        year: props.vehicle.year,
        make: props.vehicle.make,
        model: props.vehicle.model,
        mileage: props.vehicle.mileage,
        dvi_url: props.vehicle.dvi.pdf,
        shop_number: props.vehicle.dvi.shop.store_no,
        shop_name: props.vehicle.dvi.shop.name,
        shop_address1: props.vehicle.dvi.shop.line1,
        shop_address2: props.vehicle.dvi.shop.line2,
        shop_city: props.vehicle.dvi.shop.city,
        shop_state: props.vehicle.dvi.shop.state,
        shop_zip: props.vehicle.dvi.shop.postal_code,
      },
      completed_pages: {
        ...context.completed_pages,
        confirm_vehicle: true,
      },
    });
    navigate(Paths.ConfirmDetails);
  }
  function renderInspection() {
    if (typeof props.vehicle.is_eligible === "undefined") {
      return;
    } else if (location.pathname === Paths.ConfirmVehicle) {
      if (props.vehicle.is_eligible) {
        return (
          <Paper
            shadow="0"
            radius="md"
            p="16px"
            withBorder
            style={{
              borderWidth: "2px",
              borderColor: "#006B5B",
              color: "#004C3D",
              marginTop: "16px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <ThemeIcon size="md" color="#FFF">
                <IconTool
                  color="#006B5B"
                  style={{ transform: "rotateY(180deg)" }}
                ></IconTool>
              </ThemeIcon>
              <div style={{ marginLeft: "8px" }}>
                <div className="inspection-header">
                  {t("recent-inspection-completed")}{" "}
                  {formatDate(props?.vehicle?.dvi?.date)}
                </div>
                <div className="inspection-subheader">{t("offer-valid")}</div>
              </div>
            </div>
          </Paper>
        );
      } else if (props.vehicle.ineligibility_reason) {
        return (
          <Paper
            shadow="0"
            radius="md"
            p="16px"
            withBorder
            style={{
              borderWidth: "2px",
              borderColor: "#F5A800",
              color: "#212529",
              marginTop: "16px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <ThemeIcon size="md" color="#FFF">
                <IconAlertTriangle color="#F5A800"></IconAlertTriangle>
              </ThemeIcon>
              <div style={{ marginLeft: "8px" }}>
                <div className="inspection-header">
                  {generateIneligibilityText()}
                </div>
              </div>
            </div>
          </Paper>
        );
      }
    }
  }

  function renderSelectButton() {
    if (location.pathname === Paths.ConfirmVehicle) {
      if (
        props.vehicle.ineligibility_reason === IneligibilityReasons.INVALID_DVI
      ) {
        return (
          <Button
            className="button"
            fullWidth
            color="#FF671D"
            onClick={() =>
              window.open(
                process.env.REACT_APP_SCHEDULER_URL +
                  `/?careplus=true&vehicleId=${props.vehicle.vehicle_guid}`,
                "_blank",
                "noreferrer"
              )
            }
          >
            {t("schedule-inspection")}
          </Button>
        );
      } else {
        return (
          <Button
            className="button"
            fullWidth
            color="#FF671D"
            disabled={continueDisabled}
            onClick={() => handleVehicleSelection()}
          >
            {t("select-vehicle")}
          </Button>
        );
      }
    }
  }

  function renderEstimatedQuote() {
    if (
      props.vehicle.estimated_quote > 0 &&
      (!props.vehicle.ineligibility_reason || props.vehicle.ineligibility_reason === "INVALID_DVI") &&
      location.pathname === Paths.ConfirmVehicle
    ) {
      return (
        <Text styles={{ root: { fontFamily: "Montserrat", marginLeft: "8px" } }}>
          <Trans
            i18nKey={"vehicle-card-quote"}
            t={t}
            values={{ boldOrangeText }}
            components={{
              boldorange: boldOrangeText("vehicle-card-quote"),
              quote: props.vehicle.estimated_quote
            }}
          />
        </Text>
      );
    }
  }

  return (
    <Paper
      shadow="0"
      radius="md"
      p="16px"
      withBorder
      style={{ marginBottom: "32px", borderWidth: "2px" }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <ThemeIcon size="lg" color="#E5FBF7">
          <IconCar
            color="#00C08B"
            style={{ width: "75%", height: "75%" }}
          ></IconCar>
        </ThemeIcon>
        <div className="vehicle-details-container">
          <div className="vehicle-details-header">
            {props.vehicle.year} {props.vehicle.make} {props.vehicle.model}
          </div>
          <div className="vehicle-details-subheader">
            {numberWithCommas(props.vehicle?.mileage)}
          </div>
          {renderEstimatedQuote()}
        </div>
      </div>
      {renderInspection()}
      {renderSelectButton()}
    </Paper>
  );
};
