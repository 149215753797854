import { React, useContext, useEffect, useState } from "react";
import { PreviousButton } from "../PreviousButton/PreviousButton";
import {
  Modal,
  Flex,
  Loader,
  Checkbox,
  Center,
  Title,
  Button,
  Stack,
  Text,
} from "@mantine/core";
import "./AgreementsForm.scss";
import { Paths } from "../../models/constants/paths";
import { Paper, Space } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../context/GlobalContext";
import { IconReport, IconSignature, IconChecks } from "@tabler/icons-react";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { CarePlusEndpoints } from "../../models/constants/carePlusEndpoints";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { PdfViewer } from "../PdfViewer/PdfViewer";
import { appInsights } from "../ApplicationInsights/ApplicationInsights.jsx";

export const AgreementsForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { context, setContext } = useContext(GlobalContext);
  let [isContractSigned, setContractSigned] = useState(false);
  let [isDVIReviewed, setDVIReviewed] = useState(false);
  let [isContinueButtonDisabled, setContinueButtonDisabled] = useState(true);
  let [isSignatureButtonDisabled, setSignatureButtonDisabled] = useState(true); //update signature button to be enabled if event = declined from docusign.
  let [isSignatureButtonClicked, setSignatureButtonClicked] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [loading, setLoading] = useState(true);
  const [isDviConfirmed, setIsDviConfirmed] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const event = queryParams.get("event");
  const pdfURL = `https://drive.google.com/viewerng/viewer?url=${context.vehicle_data.dvi_url}&embedded=true`;
  const heightOffset = useMediaQuery("(max-width: 507px)") ? 370 : 320;

  const openDvi = () => {
    setLoading(true);
    open();
  };

  const handleContinue = () => {
    setDVIReviewed(true);
    setSignatureButtonDisabled(false);
    localStorage.setItem("dviReviewStatus", isDVIReviewed);
    close();
  };

  const continueToPayment = () => {
    setContext({
      ...context,
      completed_pages: {
        ...context.completed_pages,
        agreements: true,
      },
    });
    navigate(Paths.Payment);
  };

  const displayContract = () => {
    setSignatureButtonDisabled(true);
    setSignatureButtonClicked(true);
    getContractUrl()
      .then((res) => {
        setContext({
          ...context,
          docusign: { envelope_id: res.data.envelope_id },
        });
        localStorage.setItem("envelope_id", res.data.envelope_id);
        localStorage.setItem("context", JSON.stringify(context));
        window.location.assign(res.data.url);
      })
      .catch((error) => {
        appInsights.trackException({ error: new Error(error), severityLevel: 1 });
        navigate(Paths.Error);
      });
  };

  const getContractUrl = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BACKEND_API_URL + CarePlusEndpoints.Signature,
        {
          pdf: context.stripe_subscription_data.contract_pdf,
          signatureCoordinates:
            context.stripe_subscription_data.signature_coordinates,
        },
        {
          params: {
            email: context.user.email,
            name: context.user.first_name + " " + context.user.last_name,
            dgbId: context.user.dgb_id,
            callback_url: process.env.REACT_APP_DOCUSIGN_CALLBACK_URL,
          },
          timeout: process.env.REACT_APP_API_TIMEOUT
        }
      );
      return response;
    } catch (error) {
      appInsights.trackException({ error: new Error(error), severityLevel: 1 });
      navigate(Paths.Error);
    }
  };

  const modalHeader = () => {
    return (
      <Stack>
        <Text ff="Crimson Text" fz="24px">
          {t("review-dvi-text-2")}
        </Text>
        <Button
          styles={{
            root: {
              fontFamily: "Montserrat",
              background: "#006B5B",
              width: "160px",
            },
          }}
          onClick={() => {
            window.open(context.vehicle_data.dvi_url);
          }}
        >
          {t("download-pdf")}
        </Button>
      </Stack>
    );
  };

  useEffect(() => {
    if (localStorage.getItem("context")) {
      let contextJson = JSON.parse(localStorage.getItem("context"));
      contextJson.docusign.envelope_id = localStorage.getItem("envelope_id");
      setContext(contextJson);
      setDVIReviewed(localStorage.getItem("dviReviewStatus"));
      if (event === "signing_complete") {
        setContractSigned(true);
        setContinueButtonDisabled(false);
      } else {
        setContractSigned(false);
        setContinueButtonDisabled(true);
        setSignatureButtonDisabled(false);
      }
    }
    localStorage.removeItem("context");
  }, []);

  useEffect(() => {
    if (context.completed_pages.agreements) {
      setDVIReviewed(true);
      setContractSigned(true);
      setContinueButtonDisabled(false);
    }
  }, []);

  return (
    <>
      <div className="full-container">
        <div className="content-container">
          <PreviousButton path={Paths.FinalQuote} />
          <div className="header">
            <div className="header_1">{t("agreements")}</div>
          </div>
          <div className="subheader">{t("agreements-text")}</div>
          {isDVIReviewed ? (
            <Paper
              shadow="0"
              p="16px"
              withBorder
              style={{
                borderWidth: "2px",
                borderColor: "#006B5B",
                color: "#212529",
              }}
            >
              <Paper
                style={{
                  flexDirection: "row",
                  display: "flex",
                }}
              >
                <div>
                  <IconChecks color="#006B5B" />
                </div>
                <div className="agreements-header">
                  <Title
                    ff="Montserrat"
                    fz="16px"
                    lh={"155%"}
                    fs="normal"
                    fw="600px"
                    c="#006B5B"
                  >
                    {t("review-dvi-complete-text")}
                  </Title>
                </div>
              </Paper>
            </Paper>
          ) : (
            <Paper
              shadow="0"
              p="16px"
              withBorder
              sx={{ p: "50px", borderRadius: "20px", borderColor: "green" }}
            >
              <Paper
                style={{
                  flexDirection: "row",
                  display: "flex",
                }}
              >
                <div>
                  <IconReport color="#000000" />
                </div>
                <div className="agreements-header">{t("review-dvi-text")}</div>
              </Paper>
              <Modal
                opened={opened}
                onClose={() => {
                  setIsDviConfirmed(false);
                  close();
                }}
                fullScreen
                padding={0}
                title={modalHeader()}
                styles={{
                  title: {
                    fontFamily: "Crimson Text",
                    fontSize: "32px",
                    lineHeight: "125%",
                    color: "#212529",
                    fontStyle: "normal",
                    fontWeight: "600",
                  },
                  header: {
                    padding: "24px",
                  },
                }}
                transitionProps={{ transition: "fade", duration: 200 }}
              >
                <PdfViewer
                  url={pdfURL}
                  height={document.documentElement.clientHeight - heightOffset}
                />
                <div className="sticky-div">
                  <div className="sticky-div-content">
                    <Center>
                      <Flex>
                        <Center>
                          <Checkbox
                            id="checkbox"
                            color="#FF671D"
                            onChange={(event) => {
                              setIsDviConfirmed(event.currentTarget.checked);
                            }}
                          />
                        </Center>
                        <div className="dvi-checkboxtext">
                          <Title
                            ff="Montserrat"
                            fz="14px"
                            lh="145%"
                            c="#101113"
                            fs="normal"
                            fw="400"
                          >
                            {t("dvi-acknowledgment")}
                          </Title>
                        </div>
                      </Flex>
                    </Center>
                    <Button
                      className="button"
                      disabled={!isDviConfirmed}
                      fullWidth
                      color="#FF671D"
                      onClick={() => handleContinue()}
                    >
                      {t("continue")}
                    </Button>
                  </div>
                </div>
              </Modal>
              <Button
                className="button"
                fullWidth
                color="#FF671D"
                onClick={() => openDvi()}
              >
                {t("review-dvi")}
              </Button>
            </Paper>
          )}

          <Space h="md" />

          {isContractSigned ? (
            <Paper
              shadow="0"
              p="16px"
              withBorder
              style={{
                borderWidth: "2px",
                borderColor: "#006B5B",
                color: "#212529",
              }}
            >
              <Paper
                style={{
                  flexDirection: "row",
                  display: "flex",
                }}
              >
                <div>
                  <IconChecks color="#006B5B" />
                </div>
                <div className="agreements-header">
                  <Title
                    ff="Montserrat"
                    fz="16px"
                    lh={"155%"}
                    fs="normal"
                    fw="600px"
                    c="#006B5B"
                  >
                    {t("sign-contract-complete")}
                  </Title>
                </div>
              </Paper>
            </Paper>
          ) : (
            <Paper shadow="0" p="16px" withBorder>
              <Paper
                shadow="0"
                style={{
                  flexDirection: "row",
                  display: "flex",
                }}
              >
                <div>
                  <IconSignature color="#000000" />
                </div>
                <div className="agreements-header">
                  {t("sign-contract-title")}
                </div>
              </Paper>
              <Button
                className="button"
                disabled={isSignatureButtonDisabled}
                fullWidth
                color="#FF671D"
                onClick={() => displayContract()}
              >
                {isSignatureButtonClicked ? (
                  <Center>
                    <Loader color="#006B5B" />
                  </Center>
                ) : (
                  t("sign-contract")
                )}
              </Button>
            </Paper>
          )}
          <Button
            className="button"
            disabled={isContinueButtonDisabled}
            fullWidth
            color="#FF671D"
            style={{ marginBottom: "32px" }}
            onClick={() => continueToPayment()}
          >
            {t("continue-payment")}
          </Button>
        </div>
      </div>
    </>
  );
};
