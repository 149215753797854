import {
  useStripe,
  AddressElement,
  useElements,
} from "@stripe/react-stripe-js";
import { React, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../context/GlobalContext";
import { PreviousButton } from "../PreviousButton/PreviousButton";
import { Paths } from "../../models/constants/paths";
import { Button, TextInput } from "@mantine/core";

const ConfirmAddressForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const stripe = useStripe();
  const { context, setContext } = useContext(GlobalContext);
  const elements = useElements();
  /* const [email, setEmail] = useState(context.user.email || "");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [emailError, setEmailError] = useState(""); */

  useEffect(() => {
    if (!stripe) {
      return;
    }
  }, [stripe]);

  /* useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(email);

    setIsEmailValid(isValid);
  }, [email]);

  useEffect(() => {
    if (isEmailValid) {
      setEmailError("");
    }
  }, [isEmailValid]) */

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    /* if (!isEmailValid) {
      setEmailError(t("billing-email-required"));
      return;
    } */

    localStorage.setItem("context", JSON.stringify(context));

    const addressElement = elements.getElement("address");
    const { complete, value } = await addressElement.getValue();

    if (complete /*&& isEmailValid */) {
      setContext({
        ...context,
        user: {
          ...context.user,
          mailing_address: {
            line1: value.address.line1,
            line2: value.address.line2,
            city: value.address.city,
            state: value.address.state,
            postal_code: value.address.postal_code,
            country: value.address.country,
          },
          email: context.user.email,
          first_name: value.firstName,
          last_name: value.lastName,
        },
        completed_pages: {
          ...context.completed_pages,
          confirm_address: true,
        },
      });
      navigate(Paths.FinalQuote);
    }
    localStorage.clear();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="full-container">
        <div className="content-container">
          <PreviousButton path={Paths.ConfirmDetails} />
          <div className="header">
            <div className="header_1">{t("confirm-contact-information")}</div>
          </div>
          <div className="subheader">{t("mailing-address-text")}</div>
          {/*
          <div>
            <div className="second-subheader">{t("billing-email")}</div>
            <TextInput
              placeholder={t("billing-email-placeholder")}
              maxLength={50}
              required
              error={emailError}
              size="md"
              value={email}
              styles={{
                root: { marginBottom: "16px" },
                label: { fontWeight: "normal" },
                input: { fontFamily: 'initial', fontStyle:'sans-serif' , fontSize: '16px'} 
              }}
              onChange={(event) => setEmail(event.currentTarget.value)}
              />
              </div> 
            */}
            <div className="second-subheader">{t("mailing-address")}</div> 
            <AddressElement options={{
              mode: 'shipping', 
              display: {name : "split"},
              defaultValues: {
                firstName: context.user.first_name,
                lastName: context.user.last_name,
                address: {
                  line1: context.user.mailing_address.line1,
                  line2: context.user.mailing_address.line2,
                  city: context.user.mailing_address.city,
                  state: context.user.mailing_address.state,
                  postal_code: context.user.mailing_address.postal_code,
                  country: context.user.mailing_address.country,
                },
              },
            }}
          />
          <Button className="button" type="submit" fullWidth color="#FF671D">
            {t("confirm")}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ConfirmAddressForm;
